<template>
  <div id="result-class-booking-component">
    <BRow v-if="!isEmpty(getSearchFlightArray)">
      <BCol
        cols="12"
        lg="6"
        :class="['xs', 'sm', 'md'].includes(appBreakPoint) ? '' : 'pr-0'"
      >
        <b-tabs
          v-model="tabIndex"
          pills
          nav-class="tab-title"
          align="center"
          nav-wrapper-class="nav-wrapper-class"
        >
          <b-tab
            v-for="(itinerary, itineraryIndex) in getSearchFlightArray"
            :key="itineraryIndex"
          >
            <template #title>
              <div class="d-flex-column">
                <div
                  :class="`d-flex text_info_class_booking fw-700 mb-50 text-nowrap
                ${ ['xs', 'sm', 'MD'].includes(appBreakPoint) ? 'font-medium-1' : 'font-medium-3' }`"
                >
                  #{{ itineraryIndex + 1 }} {{ itinerary.startPoint }}-{{ itinerary.endPoint }} {{ convertISODateTime(itinerary.departDate).dayAndMonth }}
                </div>
                <div
                  v-if="!selectedTrips[itineraryIndex] || !selectedTrips[itineraryIndex][0]"
                  class="d-flex-center"
                >
                  <span class="text-warning font-weight-bolder font-small-4">
                    Chưa chọn chuyến
                  </span>
                </div>
                <div
                  v-else-if="
                    selectedTrips[itineraryIndex] &&
                      selectedTrips[itineraryIndex][0]
                  "
                  class="d-flex-center"
                >
                  <div class="text-nowrap font-weight-bolder">
                    {{ selectedTrips[itineraryIndex][0].airline
                    }}{{ selectedTrips[itineraryIndex][0].flightNumber }}
                    {{ selectedTrips[itineraryIndex][0].bookingClass.code }}
                    {{
                      convertISODateTime(
                        selectedTrips[itineraryIndex][0].departureDate,
                        selectedTrips[itineraryIndex][0].departureTimezone,
                      ).hourMin
                    }}
                    {{
                      convertISODateTime(
                        selectedTrips[itineraryIndex][0].arrivalDate,
                        selectedTrips[itineraryIndex][0].arrivalTimezone,
                      ).hourMin
                    }}
                  </div>
                </div>
              </div>
            </template>

            <!-- ANCHOR - TRIPS -->
            <template v-if="flightsData && flightsData[itineraryIndex] && flightsData[itineraryIndex].trips && !isEmpty(flightsData[itineraryIndex].trips)">
              <!-- NOTE Từng hành trình search -->
              <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="flight-details scroll-area"
              >
                <b-card
                  v-for="(trip, tripIndex) in flightsData[itineraryIndex].trips"
                  :key="tripIndex"
                  class="border shadow mb-50"
                  body-class="px-50 py-50"
                >
                  <b-row
                    v-for="(segment, segmentIndex) in trip.segments"
                    :key="segmentIndex"
                    no-gutters
                  >
                    <b-col
                      cols="12"
                      class="text-dark d-flex flex-wrap"
                      :class="segmentIndex > 0 ? 'pl-2' : 'pl-25'"
                    >
                      <div class="d-flex align-items-center fw-700 font-medium-1 mr-75">
                        <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).time }}-{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time }}</span>
                        <span class="mr-50 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth }}</span>
                        <span class="mr-50 text-nowrap">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                        <span class="mr-50 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
                        <span class="text-nowrap">{{ `| ${segment.airCraft}` }}</span>
                      </div>
                      <!-- <b-button
                    class="float-right rounded-lg px-50 py-25 my-25 mr-25 ml-0"
                    variant="flat-warning"
                    style="min-width: 80px;"
                    size="sm"
                    @click="handleShowModalInfoTrip(segment)"
                  >
                    <span class="border-bottom-warning">Tình trạng chuyến bay</span>
                  </b-button> -->
                    </b-col>

                    <b-col
                      cols="12"
                      :class="segmentIndex > 0 ? 'pl-2' : ''"
                    >
                      <!-- style="min-width: 30px; min-height: 35px" -->
                      <b-button
                        v-for="(bookingClass, index) in segment.bookingClassAvail"
                        :id="`btn-booking-class-${bookingClass.uniqueSegmentId}`"
                        :key="index"
                        variant="transparent"
                        class="mr-50 mb-25 px-25 py-0"
                        :class="`
                    btn_booking_class mr-25 mb-25 px-25 py-0
                    ${resolveClassColor(bookingClass)}
                    `"
                        style="min-width: 30px"
                        @click="handleSelectClassBooking(segment, bookingClass)"
                      >
                        <div class="fw-700">
                          {{ `${bookingClass.code}${bookingClass.availability}` }}
                        </div>

                        <b-tooltip
                          v-if="appBreakPoint !== 'xs'"
                          :id="`tooltip-booking-class-${bookingClass.uniqueSegmentId}`"
                          :target="`btn-booking-class-${bookingClass.uniqueSegmentId}`"
                          triggers="hover"
                          custom-class="tooltip-index-2"
                          placement="top"
                          boundary="viewport"
                          :variant="bookingClass.availability === 0 ? 'secondary' : 'info'"
                        >
                          <h6 class="text-white py-25 mb-0">
                            {{ bookingClass.fareBasisCode }}
                          </h6>
                          <i class="mb-0 font-medium-2">
                            {{ bookingClass.fare ? formatCurrency(bookingClass.fare) : 0 }}
                          </i>
                        </b-tooltip>
                      </b-button>
                      <i class="text-danger font-weight-bolder text-uppercase text-nowrap font-small-3">
                        {{ segment.operating ? getAirlineNameByCode(segment.operating) : '' }}
                      </i>
                    </b-col>
                  </b-row>
                </b-card>
              </vue-perfect-scrollbar>
            </template>

            <b-alert
              v-else-if="flightsData && flightsData[itineraryIndex] && flightsData[itineraryIndex].trips && isEmpty(flightsData[itineraryIndex].trips)"
              variant="danger"
              show
              class="text-center px-50 px-lg-0 py-75 py-lg-1 my-2 font-weight-bolder font-medium-2"
            >
              <feather-icon
                icon="InfoIcon"
                size="21"
                class="text-danger mr-25"
                style="margin-bottom: 3px"
              />
              Không có chuyến bay được khai thác trên hành trình này
            </b-alert>

            <div
              v-else
              class="text-center mt-3"
              style="min-height: 100px"
            >
              <b-spinner variant="info" />
              <p class="text-info">
                Đang tìm kiếm chuyến bay...
              </p>
            </div>
          </b-tab>
        </b-tabs>
      </BCol>

      <BCol
        cols="12"
        lg="6"
      >
        <Footer
          :arr-selected-trips="selectedTrips"
          :total-fare="totalFare"
          :payload-search="searchData"
          :flights-data="flightsData"
          :booking-data="bookingData"
          :search-flight-array="getSearchFlightArray"
          :status-segment="status"
          @handleChangeStatus="handleChangeStatus"
        />
      </BCol>
    </BRow>

    <!-- <ModalInfoTrip
      :segment-info="segmentInfo"
    /> -->
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BButton,
  BCard,
  BCol,
  BRow,
  BTooltip,
  VBTooltip,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import {
  ref, onBeforeMount, onUnmounted, computed,
} from '@vue/composition-api'
import { isEmpty, cloneDeep } from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import store from '@/store'

import {
  formatCurrency,
  convertISODateTime,
  getDate,
  formatVnCurrency,
} from '@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

import useToast from '@useToast'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCard,
    BCol,
    BRow,
    BTooltip,
    BSpinner,
    BAlert,

    VuePerfectScrollbar,

    Footer: () => import('./footer.vue'),
    // ModalInfoTrip: () => import('@flightv2/ModalInfoTrip.vue'),

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['app-reservation/getTabIndex']
      },
      set(newValue) {
        this.$store.dispatch('app-reservation/setTabIndex', newValue)
      },
    },
  },
  setup() {
    const { toastError } = useToast()
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) { store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule) }
    onUnmounted(() => { if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) { store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME) } })

    const {
      resultSearchFlight,
      searchClassBooking,
      getAirlineNameByCode,
      getSearchFlightArray,
      getClassBookingInfoTrip,
    } = useReservationDetailBookingHandle()

    const query = localStorage.getItem('search-for-modify')
    const searchData = ref({ ...JSON.parse(query) })
    const getBookingData = localStorage.getItem('bookingData')
    const bookingData = ref({ ...JSON.parse(getBookingData) })
    const loading = ref(false)
    const flightsData = ref(null)
    const indexBookingClassShowPrice = ref(-1)
    const selectedTrips = ref([])
    const status = ref([])
    const listSelectedClassId = ref([])
    const totalFare = ref(0)
    const segmentInfo = ref([])

    function getUniqueBookingClassIds(data) {
      return data
        .flat()
        .flatMap(item => item && item.bookingClass && item.bookingClass.uniqueBookingClassId
          ? [item.bookingClass.uniqueBookingClassId]
          : [])
        .filter(Boolean)
    }

    function getTotalFare(data) {
      let totalFare = 0
      data.flat().forEach(item => {
        if (item && item.bookingClass && item.bookingClass.fare) {
          totalFare += item.bookingClass.fare
        }
      })
      return totalFare
    }

    async function handleShowModalInfoTrip(segment) {
      const payloadSearch = { ...JSON.parse(localStorage.getItem('searchFlight')) }
      segmentInfo.value = []
      this.$bvModal.show('modal-api-loading')
      try {
        const payload = { classBookingId: segment.bookingClassId }
        getClassBookingInfoTrip(payload, payloadSearch, { segmentIndex: segment.leg, legsTrip: segment.legsTrip })
          .then(res => {
            segmentInfo.value = res
            this.$bvModal.show('modal-flightv2-show-info-trip')
          })
          .catch(err => {
            toastError({
              title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
            })
            console.error({ err })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
      } catch (error) {
        console.error({ error })
        toastError({
          title: 'Lỗi lấy thông tin hành trình, vui lòng thao tác lại!',
        })
      }
    }

    function handleSelectClassBooking(segment, bookingClass) {
      const dataTrip = selectedTrips.value[segment.itineraryIndex]
      const equalTripIndex = dataTrip?.find(item => item)?.tripIndex === segment.tripIndex || undefined
      const { bookingClassAvail, ...rest } = segment
      const tripDraft = cloneDeep(selectedTrips.value)
      const statusDraft = cloneDeep(status.value)

      const updateDrafts = reset => {
        if (reset) {
          tripDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => null)]
          statusDraft[segment.itineraryIndex] = [...Array.from({ length: segment.legsTrip }, () => 'NN')]
        }
        tripDraft[segment.itineraryIndex][segment.leg] = { ...rest, bookingClass }
        statusDraft[segment.itineraryIndex][segment.leg] = bookingClass.availability !== 0 ? 'NN' : 'LL'
        selectedTrips.value = tripDraft
        status.value = statusDraft
      }

      if (!isEmpty(dataTrip)) { // Có data
        if (equalTripIndex) { // Khi đã chọn segment tương ứng với index của segment trong cùng Trip và Itinerary
          updateDrafts(false)
        } else { // Có data và chọn sang hành trình khác trong cùng Itinerary
          updateDrafts(true)
        }
      } else { // No data
        updateDrafts(true)
      }

      indexBookingClassShowPrice.value = bookingClass.uniqueBookingClassId
      totalFare.value = getTotalFare(selectedTrips.value)
      listSelectedClassId.value = getUniqueBookingClassIds(selectedTrips.value)
    }

    function addIndexSegmentToData(data) {
      let uniqueSegmentId = 0
      let uniqueBookingClassId = 0
      selectedTrips.value = data.map(() => null)
      listSelectedClassId.value = data.map(() => [])
      const result = data.map((item, itineraryIndex) => {
        const updatedTrips = item.trips.map((trip, tripIndex) => {
          const updatedSegments = trip.segments.map((segment, segmentIndex) => {
            const updatedSegment = {
              ...segment,
              uniqueSegmentId,
              legsTrip: trip.segments.length,
              leg: segmentIndex,
              tripIndex,
              itineraryIndex,
              status: 'NN',
              bookingClassAvail: segment.bookingClassAvail.map(item => {
                const result = { ...item, uniqueBookingClassId }
                uniqueBookingClassId += 1
                return result
              }),
            }
            uniqueSegmentId += 1
            return updatedSegment
          })
          return { ...trip, segments: updatedSegments }
        })
        return { ...item, trips: updatedTrips }
      })
      return result
    }

    onBeforeMount(() => {
      loading.value = true
      searchClassBooking(searchData.value)
        .then(res => {
          flightsData.value = addIndexSegmentToData(res)
        })
        .catch(err => {
          toastError({
            title: 'messagesList.error',
            content: err.message,
          })
        })
        .finally(() => {
          loading.value = false
        })
    })

    function resolveClassColor(bookingClass) { // VN1A
      if (listSelectedClassId.value.includes(bookingClass.uniqueBookingClassId)) {
        return `${bookingClass.status}_seats selected_${bookingClass.status}_seats`
      }
      return `${bookingClass.status}_seats`
    }

    function handleChangeStatus({ value, indexFlight, indexSegment }) {
      const statusDraft = cloneDeep(status.value)
      statusDraft[indexFlight][indexSegment] = value
      status.value = statusDraft
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,

      loading,
      flightsData,
      selectedTrips,
      indexBookingClassShowPrice,
      listSelectedClassId,
      resolveClassColor,
      totalFare,
      formatCurrency,
      convertISODateTime,
      getDate,
      formatVnCurrency,
      handleSelectClassBooking,

      searchData,
      status,

      resultSearchFlight,
      bookingData,

      getAirlineNameByCode,
      getSearchFlightArray,
      isEmpty,
      segmentInfo,
      handleShowModalInfoTrip,
      handleChangeStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#result-class-booking-component ::v-deep {
  .nav-wrapper-class {
    display: block;
    // display: none;
    position: sticky;
    top: 50px;
    z-index: 10;
    background: rgba(#e9f1fc, 0.8);
    backdrop-filter: blur(5px);
    margin: 0 0 2rem 0;
    border-radius: 8px;
    padding: 0;

    .nav-pills {
      margin: 0;
    }
  }

  .nav {
    flex-wrap: nowrap;
    justify-content: start !important;
    overflow-x: auto;
  }

  .tab-title .nav-item {
    min-width: auto;
    margin: 0.6rem 0px;
    background: #ffffffc5;
    box-shadow: 0px 4px 6px rgba(0, 12, 43, 0.308);
    border-radius: 8px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: .8rem;
    display: flex;
    align-items: center;

    .nav-link {
      padding: 0.8rem 1rem 0.5rem;

      @media (max-width: 576px) {
        padding: 0.5rem 0.8rem 0.5rem;
      }
    }

    .nav-link.active {
      background: #9bd5eb;
      height: 100%;
      width: 100%;
      color: #166987;
      border: none;
    }
  }
}

::v-deep .btn_booking_class {
  min-width: 30px;
  min-height: 30px;

  .tooltip {
    z-index: 2 !important;
  }
}

.NN_seats {
  color: #166987;
  border: 2px solid #166987;

  &.selected_NN_seats {
    background-color: #166987;
    color: white;
  }
}

.LL_seats {
  color: #FFC107;
  border: 2px solid #FFC107;

  &.selected_LL_seats {
    background-color: #FFC107;
    border: 2px solid #FFC107;
    color: black;
  }
}

.DS_seats {
  color: #dc3545;
  border: 2px solid #dc3545;

  &.selected_DS_seats {
    background-color: #dc3545;
    border: 2px solid #dc3545;
    color: white;
  }
}

.text_info_class_booking {
  color: #166987;
}

.sticky_refresh {
  position: sticky;
  bottom: 0;
  margin: 0.5rem 0;
  border-radius: 8px;
  padding: 0.5rem 0 !important;

  @media (max-width: 576px) {
    bottom: 3px;
  }
}

.flight-details {
  height: 70vh;
}
</style>
